import { Children, useEffect, useState } from "react";
import { ReactComponent as IconClose } from "../../assets/img/close.svg";

function Modal({ showModal, title, children, onClose, hideClose, disabled }) {
  const [showToggle, setShowToggle] = useState(showModal);
  const childArray = Children.toArray(children);

  const bodyContent = childArray.find((x) => x.type === ModalBody);
  const footerContent = childArray.find((x) => x.type === ModalFooter);

  useEffect(() => {
    setShowToggle(showModal);
  }, [showModal]);

  const toggleModal = (e) => {
    const nodeValue = e.target.attributes["class"]?.nodeValue;
    if (!nodeValue || disabled) return;

    if (nodeValue.split(" ")[0] === "modal") setShowToggle(!showToggle);
    onClose();
  };

  return (
    <div className={showToggle ? "modal-backdrop" : ""}>
      <div
        className={`modal ${showToggle ? "show-modal" : ""}`}
        onClick={toggleModal}
      >
        <div className="modal-wrapper">
          <div className={`modal-header`}>
            <h2>{title}</h2>
            {!hideClose && (
              <button className="size-medium modal-close" onClick={onClose}>
                <IconClose className="icon icon-x" />
              </button>
            )}
          </div>
          {bodyContent?.props?.children && (
            <div
              className={`modal-body${
                bodyContent?.props?.className
                  ? ` ${bodyContent?.props?.className}`
                  : ""
              }`}
            >
              {bodyContent?.props?.children}
            </div>
          )}
          {footerContent && (
            <div className={`modal-footer ${footerContent?.props?.className}`}>
              {footerContent?.props?.children}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
export default Modal;

export function ModalBody() {
  return null;
}

export function ModalFooter() {
  return null;
}
