import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import DataGrid, {
  Column,
  CustomRule,
  Editing,
  Format,
  Selection,
  Summary,
  TotalItem,
} from "devextreme-react/data-grid";
import Spinner from "../components/common/Spinner";
import { fetchAchReceiptPdf, fetchOrder, submitPayment } from "../context/order/orderActions";
import {
  CustButton,
  CustCol,
  CustDropDown,
  CustModal,
  CustRow,
  CustSwitch,
  CustText,
  ModalBody,
  ModalFooter,
  SectionTitle
} from "../components/common";
import { formatCurrency, roundDecimal } from "../helpers";
import logo from "../assets/img/logo.png";
import spinner from "../assets/img/page-loader.gif";

function Pay() {
  const [searchParams] = useSearchParams();
  const idParm = searchParams.get('id');
  const allOrdersParm = searchParams.get('all');

  const [localState, setLocalState] = useState({
    isLoading: true,
    order: {},
    gridData: [],
    config: {},
    selectedBalanceTotal: 0.0,
    selectedKeys: [],
    selectedFirstRow: false,
    completedInitialFetch: false,
    paymentSuccessful: false,
    transactionIds: [],
    ipInfo: {},
  });

  const [paymentInfo, setPaymentInfo] = useState({
    showModal: false,
    achRouting: undefined,
    achAccount: undefined,
    accountType: "checking",
    checkType: "business",
    useVault: false,
    storeToVault: false,
  });

  const handleCancelSubmit = () => {
    if (localState.isLoading) return;
    setPaymentInfo((prev) => {
      return {
        ...prev,
        showModal: false,
      };
    });
  };

  const accountTypeOptions = [
    {key: "checking", value: "checking", text: "Checking"},
    {key: "savings", value: "savings", text: "Savings"},
  ];

  const checkTypeOptions = [
    {key: "business", value: "business", text: "Business"},
    // {key: "personal", value: "personal", text: "Personal"},
  ];
  
  const isValidPaymentInfo = 
    paymentInfo.useVault ||
    (paymentInfo.achRouting?.length === 9 &&
    !!paymentInfo.achAccount?.length && paymentInfo.achAccount?.length < 18 &&
    accountTypeOptions.find((r) => r.key === paymentInfo.accountType) &&
    checkTypeOptions.find((r) => r.key === paymentInfo.checkType));

  const handleFormFieldChange = (fieldName, newVal) => {
    if (localState.isLoading) return;
    setPaymentInfo((prevData) => {
      return {
        ...prevData,
        [fieldName]: newVal,
      }
    });
  };
  
  const handleAchVerification = (e, maxLength) => {
    const newValue = e.target.value;
    if (!maxLength || newValue.length <= maxLength) {
      handleFormFieldChange(e.target.id, newValue);
    };
  };
  
  const finishSubmit = useCallback(async () => {
    if (localState.paymentSuccessful || !isValidPaymentInfo || localState.isLoading) return;

    let selectedItems = localState.gridData
      .filter((r) => localState.selectedKeys.includes(r.id))
      .map((r) => {
        return {
          id: r.id,
          amountToPay: r.amountToPay,
          customerPo: r.customerPo,
          invoicedDate: r.invoicedDate,
        };
      });

    setLocalState((prev) => {
      return {
        ...prev,
        isLoading: true,
      };
    });

    const response = await submitPayment(
      idParm,
      roundDecimal(localState.selectedBalanceTotal),
      selectedItems,
      paymentInfo,
    );
   
    setLocalState((prev) => {
      return {
        ...prev,
        isLoading: false,
        transactionIds: response?.data?.transactionIds || [],
        paymentSuccessful: response?.data?.status === 0,
      };
    });

    if (response?.data?.status === 0) {
      setPaymentInfo((prev) => {
        return {
          ...prev,
          showModal: false,
        };
      });

      await fetchAchReceiptPdf(idParm, response?.data?.transactionIds);

    } else {
      if (response.data.status === -2) {
        alert("Your payment was processed, but there was a problem applying it to your invoices.  Apologies - please do not resubmit, and contact Accounts Receivable");
      } else alert(`Payment was not processed.  ${response?.data?.message}`);
    };
  }, [isValidPaymentInfo, localState, paymentInfo, idParm]);

  useEffect(() => {
    if (!idParm || localState.completedInitialFetch) return;

    const lookupOrder = async (id) => {
      return await fetchOrder(id);  
    };

    lookupOrder(idParm).then((r) => {

      setLocalState((prev) => {
        let gridData = r.otherOpenOrders?.map((r) => {
          return {
            ...r,
            isValid: true,
          };
        });

        return {
          ...prev,
          isLoading: false,
          order: r?.order || {customer: {}},
          gridData: gridData || [],
          config: r.config || {},
          selectedBalanceTotal: 0.0,
          selectedKeys: [],
          completedInitialFetch: true,
          ipAddress: r.ipAddress,
        };
      });

      // default useVault to true if customer has a vaultId
      if (!!r.order.customer?.vaultId && !!r.order?.customer?.lastFourBankAcct) {
        setPaymentInfo((prev) => {
          return {
            ...prev,
            useVault: true,
          };
        });
      };
    }).catch((r) => {
      setLocalState((prev) => {
        return {
          ...prev,
          isLoading: false,
          order: {},
          gridData: [],
          selectedBalanceTotal: 0,
          selectedKeys: 0,
          completedInitialFetch: true,
        };
      });
    });
    
  }, [finishSubmit, localState, idParm]);

  if (!idParm || (!localState.isLoading && !localState.order.id)) {
    return (
      <h1>Order not found</h1>
    )
  };

  const order = localState.order;
  if (localState.isLoading && !order.id) return <Spinner />;

  const onSelectionChanged = ({ selectedRowKeys, selectedRowsData, forceAdd }) => {
    if (localState.paymentSuccessful || localState.isLoading || (forceAdd && localState.selectedFirstRow && !allOrdersParm)) return;

    setLocalState((prevData) => {
      let addedRowKey = null, removedRowKey = null;
      if (selectedRowKeys.length > prevData.selectedKeys.length) {
        addedRowKey = (selectedRowKeys.filter(item => !prevData.selectedKeys.includes(item)))[0];
      } else {
        if (!forceAdd) removedRowKey = (prevData.selectedKeys.filter(item => !selectedRowKeys.includes(item)))[0];
      };

      if (forceAdd) addedRowKey = selectedRowKeys[0];

      const gridData = prevData.gridData.map((item) => {
        if (item.id === addedRowKey || (selectedRowKeys.includes(item.id) && !item.amountToPay)) item.amountToPay = item.balance;
        if (item.id === removedRowKey || !selectedRowKeys.includes(item.id)) item.amountToPay = undefined;
        item.isValid = true;
        return item;
      });
      
      let selectedBalanceTotal = 0.0;
      selectedRowsData.forEach((r) => {
        selectedBalanceTotal += r.amountToPay || 0.0;
      });

      return {
        ...prevData,
        selectedKeys: selectedRowKeys.map((x) => x),
        gridData,
        selectedBalanceTotal,
        selectedFirstRow: true,
      };
    });
  };

  const validateAmount = (e) => {
    const isValid = e.value > 0 && e.value <= e.data.balance;
    
    setLocalState((prev) => {
      if (isValid) {
        let selectedBalanceTotal = 0.0;
        const gridData = prev.gridData.map((item) => {
          if (item.id === e.data.id) {
            item.amountToPay = e.value;
            item.isValid = true;
          };      
          selectedBalanceTotal += item.amountToPay || 0.0;
          return item;
        });

        return {
          ...prev,
          gridData,
          selectedBalanceTotal,
        };

      } else {
        const gridData = prev.gridData.map((item) => {
          if (item.id === e.data.id) item.isValid = false;
          return item;
        });
        return {
          ...prev,
          gridData,
        };
      };
    });
 
    return isValid;
  };

  const invalidAmountToPay = orders => {
    let invalid = false;
    orders.forEach((r) => {
      if (!r.isValid) invalid = true;
    });
    return invalid;
  }

  const handleSelectionsDone = () => {
    if (localState.paymentSuccessful || localState.isLoading) return;
    
   setPaymentInfo((prev) => {
    return {
      ...prev,
      showModal: true,
    };
   });
  };

  const initiallySelectFirstRow = (e) => {
    if (localState.selectedFirstRow || !localState.gridData.length) return;

    if (!!allOrdersParm) {
      e.component.selectAll();
      return;
    };

    // the guid may be for an order that was already paid.
    // if there are multiple orders in the grid, don't just use the first one - find the order # that correlates to the guid
    let index = localState.gridData.findIndex((r) => r.id === localState.order.id);
    if (index === -1) index = 0;

    e.component.selectRowsByIndexes(index);
    onSelectionChanged({
      selectedRowKeys: [localState.gridData[index].id],
      selectedRowsData: [{amountToPay: localState.gridData[index].balance}],
      forceAdd: true,
    });
  };

  const cancelEditIfNotSelected = (e) => {
    if (!localState.selectedKeys.includes(e.data.id)) e.cancel = true;
  };

  const onEditCanceling = (e) => {
    if (!e.changes.length) return;

    const key = e.changes[0].key;
    if (!key) return;

    setLocalState((prev) => {
      const gridData = prev.gridData.map((x) => {
        if (x.id === key) {
          x.amountToPay = x.balance;
          x.isValid = true;
        };
        return x;
      });

      return {
        ...prev,
        gridData,
      };
    });
  };

  const canUseVault = !!order?.customer?.vaultId && !!order?.customer?.lastFourBankAcct;

  return (
    <>
      <div className="mt-5">
        <CustRow className="mt-2">
          <CustCol cols={7}>
            <CustRow>
              <CustCol cols={12}>
                <h2>{order.customer?.businessName} invoice payment by ACH</h2>
              </CustCol>
            </CustRow>
            <CustRow>
              <CustCol cols={12}>
              <label className="comp-input-label">Your Rep is {order.rep?.name}, who may be contacted at {order.rep?.cellPhone}{order.rep?.email ? `, and emailed at ${order.rep.email}` : ""}</label>
              </CustCol>
            </CustRow>

            <CustRow className="mt-2">
              <CustCol cols={10}><label className="comp-input-label">Terms are {order.terms}</label></CustCol>
            </CustRow>
          </CustCol>
          <CustCol cols={5}>
            <img
              src={logo}
              className="pr-2"
              alt="logo"
              style={{ height: "12rem" }}
            />
          </CustCol>
        </CustRow>
      </div>
        
      {localState.paymentSuccessful && (
        <div className="mb-5">
          <SectionTitle>Thank you {order.customer?.businessName}!  Your payment of {formatCurrency(localState.selectedBalanceTotal)}
          &nbsp; is processing.  Transaction #{localState.transactionIds.length > 1 ? "s" : ""} {localState.transactionIds.join(", ")}</SectionTitle> 
        </div>
      )}

      {(!!localState.gridData.length && !localState.paymentSuccessful) && (
        <>
          {localState.gridData.length > 1 && !allOrdersParm && (
            <SectionTitle>Save Time! Pay for multiple orders at once by clicking the associated checkboxes</SectionTitle>
          )}
          {localState.gridData.length > 1 && !!allOrdersParm && (
            <SectionTitle>Select which orders to pay by checking / unchecking the box to the left</SectionTitle>
          )}

          <DataGrid
            id="other-invoices-grid"
            dataSource={localState.gridData}
            keyExpr="id"
            allowColumnReordering={true}
            allowColumnResizing={true}
            columnAutoWidth={true}
            onContentReady={initiallySelectFirstRow}
            onEditingStart={cancelEditIfNotSelected}
            onEditCanceling={onEditCanceling}
            rowAlternationEnabled={true}
            showBorders={true}
            onSelectionChanged={onSelectionChanged}
            disabled={localState.paymentSuccessful || localState.isLoading}
          >
            <Selection
              mode="multiple"
              selectAllMode="allPages"
              showCheckBoxesMode="always"
            />
            <Editing
              mode="cell"
              allowUpdating={true}
              allowAdding={false}
              allowDeleting={false}
            />
            <Column
              caption="Purchase Order"
              dataField="customerPo"
              width="12.4rem"
              allowEditing={false}
            />

            <Column
              caption="Invoice #"
              dataField="id"
              width="12rem"
              cellRender={(r) => `${localState.config?.orderPrefix}${r.data.id}`}
              allowEditing={false}
            />

            <Column
              caption="Invoice Date"
              dataField="invoicedDate"
              width="12.4rem"
              dataType="date"
              allowEditing={false}
            />

            <Column caption="Invoice Amt" dataField="subtotal" width="11rem" allowEditing={false}>
              <Format type="currency" precision={2} />
            </Column>

            <Column
              caption="Payments and Adj"
              dataField="totalReceipts"
              width="15rem"
              allowEditing={false}
            >
              <Format type="currency" precision={2} />
            </Column>

            {order.discountPercent && (
              <Column
                caption="Early Pay Discount"
                dataField="discountAmount"
                width="15rem"
                allowEditing={false}
                cellRender={(r) => !r.data.amountToPay || (r.data.balance === r.data.amountToPay) ? formatCurrency(r.data.discountAmount) : "$0.00"}
              >
                <Format type="currency" precision={2} />
              </Column>
            )}

            <Column caption="Amount Due" dataField="balance" width="12rem" allowEditing={false}>
              <Format type="currency" precision={2} />
            </Column>

            <Column caption="Amount to Pay today" dataField="amountToPay" width="12rem" allowEditing={true}>
              <Format type="currency" precision={2} />
              <CustomRule
                ignoreEmptyValue={true}
                message="Value must be between 0 and the Amount Due"
                validationCallback={validateAmount}
              />
            </Column>

            <Summary>
              <TotalItem
                column="balance"
                customizeText={() => "Selected Total:"}
              />

              <TotalItem
                column="amountToPay"
                summaryType="sum"
                customizeText={() => formatCurrency(localState.selectedBalanceTotal)}
              />
            </Summary>
          </DataGrid>

          <CustButton
            text={`Authorize Payment for ${formatCurrency(localState.selectedBalanceTotal)}...`}
            onClick={handleSelectionsDone}
            disabled={localState.paymentSuccessful || (roundDecimal(localState.selectedBalanceTotal) <= 0.0) ||
              invalidAmountToPay(localState.gridData) || localState.isLoading}
            tabIndex={1}
            className="mt-5"
          />
        </>
      )}

      <div className="mt-10">
        <p style={{fontSize: "80%"}}>
          {localState.ipAddress} This software was custom designed for {localState.config.clientName}. If you'd like to discuss your custom software needs, contact <a href="https://406partnership.com" target="_blank" rel="noreferrer">406 Partnership, LLC</a> 
        </p>
      </div>

      <CustModal
        id="payment-modal"
        title={`Payment Authorization`}
        showModal={paymentInfo.showModal}
        size="small"
        hideClose={true}
        onClose={() => {}}
        disabled={localState.isLoading}
      >
        <ModalBody>
          {canUseVault &&
            <CustRow>
              <CustCol cols={12}>
                <CustSwitch
                  id="use-vault"
                  label={`Would you like to use your account ending in ${order.customer.lastFourBankAcct}?`}
                  checked={paymentInfo.useVault}
                  labelPosition="left"
                  onChange={(e) => handleFormFieldChange("useVault", e.target.checked)}
                />
              </CustCol>
            </CustRow>
          }

          {!paymentInfo.useVault && 
            <div>
              <CustRow>
                <CustCol cols={3}>
                  <CustText
                    id="achRouting"
                    label="Routing Number"
                    onChange={(e) => {
                      handleAchVerification(e, 9);
                    }}
                    tabIndex={21}
                    value={paymentInfo.achRouting}
                    errorMessage="Routing # must be 9 digits"
                    valid={!!paymentInfo.achRouting && paymentInfo.achRouting.length === 9}
                    showValidationState={!!paymentInfo.achRouting}
                    type="number"
                    disabled={localState.isLoading}
                  />
                </CustCol>

                <CustCol cols={3}>
                  <CustText
                    id="achAccount"
                    label="ACH Account"
                    onChange={(e) => {
                      handleAchVerification(e, 17);
                    }}
                    tabIndex={22}
                    value={paymentInfo.achAccount}
                    type="number"
                    disabled={localState.isLoading}
                  />
                </CustCol>

                <CustCol cols={3}>
                  <CustDropDown
                    id="accountType"
                    selectedValue={paymentInfo.accountType}
                    label="Account Type"
                    onChange={(e) => {
                      handleFormFieldChange(e.target.id, e.target.value);
                    }}
                    tabIndex={23}
                    options={accountTypeOptions}
                    disabled={localState.isLoading}
                  />
                </CustCol>

                <CustCol cols={3}>
                  <CustDropDown
                    id="checkType"
                    selectedValue={paymentInfo.checkType}
                    label="Check Type"
                    onChange={(e) => {
                      handleFormFieldChange(e.target.id, e.target.value);
                    }}
                    tabIndex={24}
                    options={checkTypeOptions}
                    disabled={localState.isLoading}
                  />
                </CustCol>
              </CustRow>

              <CustRow>
                <CustCol cols={12}>
                  <CustSwitch
                    id="store-to-vault"
                    label="Store this information in the secure customer vault so it doesn't need to entered again next time?"
                    checked={paymentInfo.storeToVault}
                    labelPosition="left"
                    onChange={(e) => handleFormFieldChange("storeToVault", e.target.checked)}
                  />
                </CustCol>
              </CustRow>

              <p className="mt-5 ml-10">
                Choosing to use the vault is safe because:
                <ol>
                  <li>We only keep the last four digits of your account number, and use a 3rd party PCI DSS Level 1 vault to ensure security. </li>
                  <li>We use an approved and certified tokenization strategy, which means that  the processor will reject payments to any other vendor.</li>
                  <li>Transactions will only be processed with your consent.</li>
                  <li>You may delete this information whenever you wish.</li>
                </ol>
              </p>
            </div>
          }

          <CustRow>
            <CustCol offset={1} cols={10}>
                <p className="mt-10" style={{fontSize: "130%"}}>Clicking "Submit" will submit your payment of {formatCurrency(localState.selectedBalanceTotal)}</p>
            </CustCol>
          </CustRow>     
        </ModalBody>
        <ModalFooter>
          <p>By clicking the Submit button below, I certify that I am an authorized representative of {order.customer?.businessName} ("Company") and that I have the authority to authorize {localState.config.clientName} to Debit or Credit the Bank Account referenced above via the Automated Clearing House (ACH) system.  This authority will remain in effect until revoked in writing by Company.</p>
          <p className="mt-3">Furthermore, if the payment is returned for any reason, I authorize {localState.config.clientName} to debit a rejected/returned item fee of $25, unless that exceeds the maximum amount allowed by law, in which case the maximum amount allowed by law shall be assessed.</p>

          <CustRow className="mt-10">
            <CustCol offset={1} cols={6}>
              <CustButton
                id="submit-button"
                onClick={() => finishSubmit()}
                custType="primary"
                tabIndex={51}
                disabled={!isValidPaymentInfo || localState.isLoading}
              >
              <span className="dx-button-text">Submit payment of {formatCurrency(localState.selectedBalanceTotal)} via ACH</span>
              {localState.isLoading && <img className="spinner-button" style={{margin: "5px", paddingBottom: "5px"}} src={spinner} alt="Loading..." />}
              </CustButton>
            </CustCol>

            <CustCol cols={2}>
              <CustButton
                id="cancel-delete-button"
                onClick={handleCancelSubmit}
                type="default"
                text="Cancel"
                tabIndex={52}
                disabled={localState.isLoading}
              />
            </CustCol>
          </CustRow> 
        </ModalFooter>
      </CustModal>
    </>
  );
}

export default Pay;
