import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { AppDataProvider } from "./context/AppDataContext";
import ErrorBoundary from "./components/ErrorBoundary";
import Home from "./pages/Home";
import Navbar from "./components/Navbar";
import Pay from "./pages/Pay";
import "react-toastify/dist/ReactToastify.css";
import { NAVIGATION } from "./constants";


function App() {
  return (
    <ErrorBoundary>
      <AppDataProvider>
          <Router>
            <div className="container">
              <Navbar />
              <main>
                <Routes>
                  <Route path={NAVIGATION.pay} element={<Pay />} />

                  <Route path="*" element={<Home />} />
                </Routes>
              </main>
            </div>
          </Router>
          <ToastContainer />
      </AppDataProvider>
    </ErrorBoundary>
    );
  };


export default App;
